import React, { FunctionComponent, memo, useEffect, useRef, useState } from 'react';

interface IProps {
  src: string;
}

const MusicPostPlayer: FunctionComponent<IProps> = memo(({ src }) => {
  const aspectRatio = 16 / 9;
  const iframeEle = useRef(null);
  const [embedSrc, setEmbedSrc] = useState('');

  const resizeIframe = () => {
    const parentWidth = iframeEle.current.parentNode.clientWidth;
    iframeEle.current.width = `${parentWidth}px`;
    iframeEle.current.height = `${parentWidth / aspectRatio}px`;
  };

  useEffect(() => {
    if (src) {
      const part = src.split('?')[1];
      const queries = part ? part.split('&') : [];
      queries.forEach((query) => {
        if (query) {
          const [key, val] = query.split('=');
          if (key === 'v') {
            setEmbedSrc(val);
          }
        }
      });
    }
    resizeIframe();
    window.addEventListener('resize', resizeIframe);
    return () => {
      window.removeEventListener('resize', resizeIframe);
    };
  }, []);
  return (
    <iframe
      ref={iframeEle}
      width='100%'
      height='auto'
      src={`https://www.youtube.com/embed/${embedSrc}?autoplay=1`}
      frameBorder='0'
      allow='autoplay; encrypted-media'
      allowFullScreen
    />
  );
});

export default MusicPostPlayer;
