import React, { FunctionComponent, useState } from 'react';
import Layout from '../components/layout/layout';
import MultiLangTags from '../components/multi-lang-tags/multi-lang-tags';
import MusicPostPlayer from '../components/music-post-player/music-post-player';
import OpenGraphTags from '../components/open-graph-tags/open-graph-tags';
import PostCommentCount from '../components/post-comment-count/post-comment-count';
import PostComment from '../components/post-comment/post-comment';
import PostShareIcons from '../components/post-share-icons/post-share-icons';
import PostTags from '../components/post-tags/post-tags';
import { renderRichContent } from '../components/rich-content/rich-content';
import SeoMetaTags from '../components/seo-meta-tags/seo-meta-tags';
import { Locale } from '../models/locale';
import { IMusicVideoEntry } from '../models/music-video-entry';
import { buildCanonicalUrl } from '../utils/define';
import { useTranslation } from '../utils/i18n';
import styles from './music-post.module.scss';

interface IProps {
  pageContext: {
    post: IMusicVideoEntry;
    intl: {
      language: Locale;
    };
  };
}

const MusicPostPage: FunctionComponent<IProps> = ({ pageContext }) => {
  const { t } = useTranslation();
  const [showComment, setShowComment] = useState(false);
  const canonicalUrl = buildCanonicalUrl(pageContext.post.path, pageContext.intl.language);

  const downloadCover = async () => {
    try {
      const res = await fetch(pageContext.post.thumbnail, {});
      const url = URL.createObjectURL(await res.blob());
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${pageContext.post.title.trim()}.jpg`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <Layout lang={pageContext.intl.language} basePath={pageContext.post.basePath}>
      <SeoMetaTags
        lang={pageContext.intl.language}
        title={`${pageContext.post.title} | Beat Panda`}
        description={pageContext.post.summary}
        canonicalUrl={canonicalUrl}
      />
      <MultiLangTags path={pageContext.post.path} basePath={pageContext.post.basePath} />
      <OpenGraphTags
        url={canonicalUrl}
        title={pageContext.post.title}
        description={pageContext.post.summary}
        image={pageContext.post.thumbnail}
        video={pageContext.post.youtubeVideoUrl}
        locale={pageContext.intl.language}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className={styles.entryHeader}>
              <div className={styles.postIntro}>
                <MusicPostPlayer src={pageContext.post.youtubeVideoUrl} />
              </div>
              <h1 className={styles.entryTitle}>{pageContext.post.title}</h1>
              <div className={styles.postDetails}>
                <PostTags tags={pageContext.post.tags} searchDomain='/music' />
                <div>
                  <a href='#comments' onClick={() => setShowComment(true)}>
                    <PostCommentCount identifier={pageContext.post.id} url={canonicalUrl} />
                  </a>
                </div>
              </div>
              <div className={styles.actions}>
                <a href='http://www.youtube.com/c/BeatPanda' target='_blank' rel='nofollow'>
                  <i className='fab fa-youtube' /> {t('music.subscribe')}
                </a>
                <button onClick={downloadCover}>
                  <i className='fas fa-image' /> {t('music.save_bg')}
                </button>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <article>
              {pageContext.post.description && renderRichContent(pageContext.post.description)}
            </article>
          </div>
          <div className='col-12'>
            <div className={styles.postFooter}>
              <span>{t('post.share_on')}</span>
              <PostShareIcons
                title={pageContext.post.title}
                path={pageContext.post.path}
                lang={pageContext.intl.language}
              />
            </div>
            <div id='comments'>
              {showComment ? (
                <PostComment
                  identifier={pageContext.post.id}
                  url={canonicalUrl}
                  title={pageContext.post.title}
                />
              ) : (
                <div className={styles.showComment}>
                  <a onClick={() => setShowComment(true)}>
                    <i className='fas fa-comment-alt' />
                    {t('post.show_comment')}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MusicPostPage;
